import React, { useState, useEffect } from 'react';
import './MarketCapComparison.css';
import kabosu from './kabosu.jpg';
import monalisa from './monalisa.jpg';
import x from './X.jpg';
import discord from './discord.jpg';
import coingecko from './coingecko.jpg';
import llamaswap from './llamaswap.png';

function MarketCapComparison(){

    const [cryptoData, setCryptoData] = useState({price: null, mcap: null, flippening:null});

    useEffect(() => {

        const fetchData = async() => {
            try {
                const response = await fetch('https://api.ownthedogeflipsmona.com/getprice');
                const data = await response.json();
                setCryptoData({
                    price: data.price,
                    mcap: data.mcap,
                    flippening: data.flippening
                });
            } catch(error){
                console.error('Error fetching data: ', error);
            }
        };

        fetchData();
    }, []);

    const formatCurrency = (number, decimalPlaces) => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: decimalPlaces,
            maximumFractionDigits: decimalPlaces,
        }).format(number);
    };

    return (
        <>

        <div className='flippening-container'>
            <div>{cryptoData.flippening ? (
                <span style={{fontWeight: 'bold', fontSize: '100px'}}> {cryptoData.flippening}x </span>
                    ) : 'Loading..'} <span style={{fontSize: '100px'}}>left to the Mona Lisa Flippening</span>
                </div>
        </div>

        <div className="market-cap-container">
            <div className="image-container">
            <img src={kabosu} alt="dog"/>
            <div>$DOG Price:</div>
            <div>
                {cryptoData.price 
                ? 
                <span style={{ fontWeight: 'bold'}}> {formatCurrency(cryptoData.price, 5)}</span> 
                : 'Loading...'}</div>
            <div>$DOG Mcap:</div>
            <div>
                    {cryptoData.mcap
                    ? <span style={{ fontWeight: 'bold' }}>{formatCurrency(cryptoData.mcap, 0)}</span>
                    : 'Loading...'}
            </div>
            </div>

            <div className="image-container">
            <img src={monalisa} alt="monalisa"/>
            <div>Mona Lisa Mcap:</div>
            <div>{<span style={{fontWeight : 'bold'}}>$1,000,000,000</span>}</div>
            </div>

            </div>
        
            

            <div className='links-container'>
                <h2>Useful links:</h2>
                <div className='image-links-container'>
                    <a href="https://www.ownthedoge.com" target="_blank" rel="noopener noreferrer" className='image-link'>
                        <img src={kabosu} alt="ownthedoge"/>
                    </a>
                    <a href="https://twitter.com/ownthedoge" target="_blank" rel="noopener noreferrer" className='image-link'>
                        <img src={x} alt="Twitter"/>
                    </a>
                    <a href="https://discord.gg/cPAXuqvQ" target="_blank" rel="noopener noreferrer" className='image-link'>
                        <img src={discord} alt="Discord"/>
                    </a>
                    <a href="https://www.coingecko.com/en/coins/the-doge-nft" target="_blank" rel="noopener noreferrer" className='image-link'>
                        <img src={coingecko} alt="Coingecko"/>
                    </a>
                    <a href="https://swap.defillama.com/?chain=ethereum&from=0x0000000000000000000000000000000000000000&to=0xbaac2b4491727d78d2b78815144570b9f2fe8899" target="_blank" rel="noopener noreferrer"
                    className='image-link'>
                        <img src={llamaswap} alt="Llamaswap"/>
                    </a>
                </div>
            </div>

        </>
    );
};

export default MarketCapComparison;